.mensagem-wrapper {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:hover {
    .horario {
      display: block;
    }
  }

  &.mensagem-enviada {
    align-self: flex-end;

    .mensagem {
      background: #d6eca1;
    }

    .horario {
      text-align: right;
    }
  }

  &.mensagem-recebida {
    align-self: flex-start;

    .mensagem {
      background: #cee5fb;
    }

    .horario {
      text-align: left;
    }
  }

  .mensagem {
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 6px 5px -2px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 2px 6px 5px -2px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 2px 6px 5px -2px rgba(0, 0, 0, 0.39);
    font-size: 14px;
  }

  .notificacao {
    text-align: center;
  }

  .horario {
    margin-top: 10px;
    display: none;
    font-size: 12px;
  }
}
