.custom-node,
.dndnode {
  background: #d9d9d9;
  color: #000;
  padding: 15px 30px;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;

  &.entrada {
    background: #b6dcf9;
  }

  &.mensagem {
    background: #a0d895;
  }

  &.imagem {
    background: #ffdee9;
  }

  &.variavel {
    background: #ffe2b8;
  }

  &.condicao {
    background: #e5bb80;
  }

  &.horario {
    background: #a1a4b2;

    .react-flow__handle:nth-child(3) {
      border-color: red;
      background: #ffffff;
    }
  }

  &.finalizar {
    background: #ee91a4;
  }

  &.menu {
    background: #dddddd;

    .node-controls {
      min-width: 110px;
    }
  }

  &.setor {
    background: #ccb7a8;
  }
}

.node-controls {
  display: none;
  position: absolute;
  top: -35px;
  right: -10px;
  transition: 0.3s;
  text-align: right;

  & > * {
    margin: 0 2.5px;
  }
}

.selected {
  border: 1px solid #000;
  border-radius: 4px;
  transition: 0.3s;

  .node-controls {
    display: block;
    transition: 0.3s;
  }
}

.handles-wrapper {
  width: 100%;
  height: 10px;
  padding: 0 20px 0 28px;
  position: absolute;
  bottom: -5px;
  //background: red;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  .react-flow__handle {
    position: initial;
    margin: 0 auto;
  }
}

.react-flow__handle {
  padding: 3px;

  .handle-conteudo {
    border: 1px solid #000;
    border-radius: 3px;
    display: none;
    min-width: 20px;
    position: inhe;
    // bottom: -45px;
    // left: -20px;
    padding: 2px 5px;
    background: #ffffff;
    margin-top: 4px;
    min-width: 100px;

    p {
      margin: 0;
      text-align: left;

      &.entradas {
        font-size: 12px;
        font-weight: bold;
      }

      &.descricao {
        font-size: 11px;
      }
    }
  }

  &:hover {
    background: green;

    .handle-conteudo {
      display: block;
    }
  }
}

.accordion-body .dndnode:not(:first-child) {
  margin-top: 10px;
}

.handle-off {
  justify-self: flex-end !important;
}
