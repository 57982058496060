.node-inicial {
  width: 50px;
  height: 50px;
  background: #bfd62d;
  border-radius: 100%;
  border: 1px solid #000;
}

.react-flow__node-inicial.selected {
  border: 0;
  border-radius: 100%;
}
