.resumo-chats-container {
  .coluna {
    &:not(:first-child) {
      border-left: 1px solid #eeeeee;
    }

    & > .titulo {
      font-size: 18px;

      span {
          font-size: 14px;
      }
    }
  }

  .chat-box {
    border-radius: 5px;
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 10px;
    color: #485056;

    &:not(:first-child) {
      margin-top: 10px;
    }

    & > div {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &:hover {
      background: #eeeeee;
    }

    p {
        margin: 0;

        &.nome {
            font-size: 15px;
            font-weight: 500;
        }

        &.setor {
            i {
                font-size: 13px;
            }
        }
    }

    .datahora {
      font-size: 12px;
      align-self: flex-end;
    }
  }
}
