.fluxo-nome {
  font-size: 24px;
  color: red;
}

.fluxo-menu-botoes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
