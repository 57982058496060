.usuarios-setor-wrapper {
  display: flex;
  flex-direction: row;

  .usuarios-setor-wrapper-split:first-child {
    border-right: 1px solid #f5f6f8;
  }
  .usuarios-setor-wrapper-split {
    flex: 1;
    padding: 10px;

    h6 {
      margin-bottom: 20px;
    }
  }

  .usuarios-setor-wrapper-usuario {
    border: 1px solid #dddddd;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 20px;
    }
  }

  .usuarios-setor-wrapper-usuario:hover {
    background: #f5f6f8;
  }

  .usuarios-setor-wrapper-usuario:not(:last-child) {
    margin-bottom: 10px;
  }
}
