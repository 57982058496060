.tipo_canal {
    margin: 10px;
    padding: 20px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    p {
        margin: 0;
        font-size: 18px;
    }

    i {
        font-size: 50px;
    }

    &:hover {
        background: #ced4da;
    }
}