.box {
  text-align: center;
  cursor: pointer;
  transition: 0.3s;

  .box-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
  }

  :hover {
    transition: 0.3s;

    i {
      transition: 0.3s;
    }

    i.new {
      color: #14b538;
    }

    p {
      font-size: 20px;
      transition: 0.3s;
    }
  }

  i {
    font-size: 120px;
    margin: -20px 0;
    transition: 0.3s;
  }

  p {
    font-size: 18px;
    transition: 0.3;
  }

  i.webchat {
    color: #bed62d;
  }
}

.box.new {
  background: rgba(255, 255, 255, 0.3);
  transition: 0.3s;

  :hover {
    background: rgba(255, 255, 255, 1);
    transition: 0.3s;
  }
}
