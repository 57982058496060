.chat {
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  background: #e2feca;
  padding: 10px;
  border-radius: 10px 0 10px 10px;
  position: relative;

  img {
    width: 100%;
    margin-bottom: 10px;
  }

  p {
    padding: 0;
    margin: 0;
    word-break: break-all;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.chat::after {
  content: "";
  position: absolute;
  top: 0;
  right: -15px;
  width: 0;
  height: 0;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #e2feca;
}
